<template>
  <b-img src="@/assets/images/logo/logo.svg" fluid alt="Industry System"></b-img>
  <!-- <svg id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="425" height="289" viewBox="0 0 425 289">
    <defs>
      <linearGradient
        fill="#2ab0f3"
        id="linearGradient-1"
        x1="100%"
        y1="10.5120544%"
        x2="50%"
        y2="89.4879456%"
      >
        <stop
          stop-color="#2ab0f3"
          offset="0%"
        />
        <stop
          stop-color="#2ab0f3"
          offset="100%"
        />
      </linearGradient>
      <linearGradient
        fill="#2ab0f3"
        id="linearGradient-2"
        x1="64.0437835%"
        y1="46.3276743%"
        x2="37.373316%"
        y2="100%"
      >
        <stop
          stop-color="#2ab0f3"
          stop-opacity="0"
          offset="0%"
        />
        <stop
          stop-color="#2ab0f3"
          offset="100%"
        />
      </linearGradient>
      <filter id="filter" x="0" y="0" width="253.312" height="289" filterUnits="userSpaceOnUse">
          <feImage preserveAspectRatio="none" x="0" y="0" width="253.312" height="289" result="image" xlink:href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjUzLjMxMiIgaGVpZ2h0PSIyODkiIHZpZXdCb3g9IjAgMCAyNTMuMzEyIDI4OSI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogdXJsKCNsaW5lYXItZ3JhZGllbnQpOwogICAgICB9CiAgICA8L3N0eWxlPgogICAgPGxpbmVhckdyYWRpZW50IGlkPSJsaW5lYXItZ3JhZGllbnQiIHgxPSIyNTMuMzEyIiB5MT0iMTY5LjExOSIgeDI9IjAiIHkyPSIxMTkuODgxIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CiAgICAgIDxzdG9wIG9mZnNldD0iLTAuMjQiIHN0b3AtY29sb3I9IiM4ZDgyZmQiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxLjI0IiBzdG9wLWNvbG9yPSIjNTg0Y2RiIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8cmVjdCBjbGFzcz0iY2xzLTEiIHdpZHRoPSIyNTMuMzEyIiBoZWlnaHQ9IjI4OSIvPgo8L3N2Zz4K"/>
          <feComposite result="composite" operator="in" in2="SourceGraphic"/>
          <feBlend result="blend" in2="SourceGraphic"/>
        </filter>
        <filter id="filter-2" x="96.313" y="2.094" width="328.688" height="286.906" filterUnits="userSpaceOnUse">
          <feImage preserveAspectRatio="none" x="96.3125" y="2.09375" width="328.688" height="286.906" result="image" xlink:href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzI4LjY4OCIgaGVpZ2h0PSIyODYuOTA2IiB2aWV3Qm94PSIwIDAgMzI4LjY4OCAyODYuOTA2Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiB1cmwoI2xpbmVhci1ncmFkaWVudCk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjIwLjg5MSIgeTE9IjI4Ni45MDYiIHgyPSIzMDcuNzk3IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CiAgICAgIDxzdG9wIG9mZnNldD0iLTAuMjQiIHN0b3AtY29sb3I9IiM4ZDgyZmQiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxLjI0IiBzdG9wLWNvbG9yPSIjNjQ1N2U4Ii8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8cmVjdCBjbGFzcz0iY2xzLTEiIHdpZHRoPSIzMjguNjg4IiBoZWlnaHQ9IjI4Ni45MDYiLz4KPC9zdmc+Cg=="/>
          <feComposite result="composite" operator="in" in2="SourceGraphic"/>
          <feBlend result="blend" in2="SourceGraphic"/>
        </filter>
    </defs>
    <title>Industry System</title>
    <path id="Shape_3_copy" data-name="Shape 3 copy" class="cls-1" d="M96,213,175,60l37,38Z" transform="translate(0.02 0.01)"/>
    <polyline class="cls-2" points="0 288.93 425.28 288.93 212.75 0"/>
  </svg> -->
</template>
<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
}
</script>
